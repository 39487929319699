<template>
<div>
    <!-- Modal -->
    <div class="modal fade" id="addMenu" tabindex="-1" aria-labelledby="addMenu" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                    <img :src="require('../assets/closeModal.svg')" />
                </button>
                <form @submit.prevent="submitHandler">
                    <div class="modal-body addMenuItem">
                        <div class="row gx-0">
                            <div class="col-md-4">
                                <div class="addMenuItem__img" v-if='$store.state.admin.productParam.product.files.length==1'>
                                    <img :src="`https://elimleli2.itfactory.site${thumb}`" />
                                    <div class="addMenuItem__img-add mt-2">
                                        <!-- <label>
                                            Изменить фотографию
                                            <input type="file" ref="file" v-on:change="handleFileUpload()">
                                        </label> -->
                                    </div>
                                </div>
                                <div class="food-item__noimg" v-else></div>
                                <!-- ///////////////////// -->
                                <!-- <button @click="submitFile()">load</button> -->
                            </div>
                            <div class="col-md-8 ps-3">
                                <div class="addMenuItem__header">Редактирование блюда</div>
                                <div class="addMenuItemInput">
                                    <label for="Dishname">Название блюда</label>
                                    <input type="text" id="Dishname" class="w-100" v-model.trim="$v.name.$model" disabled>
                                    <div class="invalid-feedback d-block" v-if="$v.name.$dirty && !$v.name.required">Введите название блюда</div>
                                </div>
                                <div class="row mt-4 gx-2">
                                    <div class="col-8">
                                        <div class="addMenuItemInput maw403">
                                            <label for="desc">Описание</label>
                                            <input id="desc" type="text" v-model.trim="$v.desc.$model" disabled>
                                            <div class="invalid-feedback d-block" v-if="$v.desc.$dirty && !$v.desc.required">Введите описание блюда</div>
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <div class="addMenuItemInput">
                                            <label for="price">Стоимость (руб.)</label>
                                            <input type="number" id="price" v-model.trim="$v.price.$model" disabled>
                                            <div class="invalid-feedback d-block" v-if="$v.price.$dirty && !$v.price.required">Введите стоимость</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-4 pt-2 gx-0 justify-content-between">

                            <div class="col-auto">
                                <div class="addMenuItemInput maw169">
                                    <label for="Weight">Вес (гр.)</label>
                                    <input type="number" id="Weight" v-model.trim="$v.weight.$model" disabled>
                                    <div class="invalid-feedback d-block" v-if="$v.weight.$dirty && !$v.weight.required">Введите вес</div>
                                </div>
                            </div>
                            <div class="col-auto">
                                <div class="addMenuItemInput maw169">
                                    <label for="Calories">Калории</label>
                                    <input type="number" id="Calories" v-model.trim="$v.calories.$model" disabled>
                                    <div class="invalid-feedback d-block" v-if="$v.calories.$dirty && !$v.calories.required">Введите калории</div>
                                </div>
                            </div>
                            <div class="col-auto">
                                <div class="addMenuItemInput maw169">
                                    <label for="Proteins">Белки (гр.)</label>
                                    <the-mask type="number" mask="####" id="Proteins" v-model.trim="$v.proteins.$model"/>
                                    <div class="invalid-feedback d-block" v-if="$v.proteins.$dirty && !$v.proteins.required">Введите белки</div>
                                </div>
                            </div>
                            <div class="col-auto">
                                <div class="addMenuItemInput maw169">
                                    <label for="Fat">Жиры (гр.)</label>
                                    <the-mask type="number" mask="####" id="Fat" v-model.trim="$v.fat.$model"/>
                                    <div class="invalid-feedback d-block" v-if="$v.fat.$dirty && !$v.fat.required">Введите жиры</div>
                                </div>
                            </div>
                            <div class="col-auto">
                                <div class="addMenuItemInput maw169">
                                    <label for="Carbohydrates">Углеводы (гр.)</label>
                                    <the-mask type="number" mask="####" id="Carbohydrates" v-model.trim="$v.carbohydrates.$model"/>
                                    <div class="invalid-feedback d-block" v-if="$v.carbohydrates.$dirty && !$v.carbohydrates.required">Введите углеводы</div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-4 pt-3">
                            <div class="col-md-12">

                                <h3 class="available-time-header available-time-header--low">Диетические параметры</h3>
                                <div class="available-time pt-2">
                                    <div class="available-time__item mb-2 pb-1">
                                        <template v-for="item in dietaryOptions">
                                            <input type="checkbox" :id="item.id" :name="item.name" :value="item.id" :key='item.id' :checked="item.is_active" @change="changeSelect(item.id)" />
                                            <label :for="item.id" :key='item.id'>{{item.name}}</label>
                                        </template>
                                        <!-- <input type="checkbox" id="1" name="" value="8:00 - 8:30" />
                                        <label for="1">Жареное</label>
                                        <input type="checkbox" id="2" name="" value="8:00 - 10:59" />
                                        <label for="2">Копченое</label>
                                        <input type="checkbox" id="3" name="" value="orange" />
                                        <label for="3">Соления</label>
                                        <input type="checkbox" id="4" name="" value="8:00 - 10:59" />
                                        <label for="4">Мучное</label>
                                        <input type="checkbox" id="5" name="" value="8:00 - 10:59" />
                                        <label for="5">Пряности и специи</label>
                                        <input type="checkbox" id="6" name="" value="8:00 - 10:59" />
                                        <label for="6">Мясо (кроме свинины)</label>
                                        <input type="checkbox" id="7" name="" value="8:00 - 10:59" />
                                        <label for="7">Свинина</label>
                                        <input type="checkbox" id="8" name="" value="8:00 - 10:59" />
                                        <label for="8">Яйца</label>
                                        <input type="checkbox" id="9" name="" value="8:00 - 10:59" />
                                        <label for="9">Рыба</label> -->
                                    </div>
                                </div>

                            </div>
                            <div class="col-md-12">

                                <h3 class="available-time-header available-time-header--low">Тип</h3>
                                <div class="available-time pt-2">
                                    <div class="available-time__item mb-2 pb-1">
                                        <input type="checkbox" id="1" name="1" v-model='is_breakfast' :checked="is_breakfast" />
                                        <label for="1">Завтрак</label>
                                        <input type="checkbox" id="3" name="3" v-model='is_lunch' :checked="is_lunch" />
                                        <label for="3">Обед</label>
                                        <input type="checkbox" id="2" name="2" v-model='is_dinner' :checked="is_dinner" />
                                        <label for="2">Ужин</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="row mt-2 pt-3">
                        <div class="col-md-6">
                            <div class="addMenuItem__subheader mb-3">Тип обеда:</div>
                            <div class="row">
                                <div class="col-auto">
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="" id="p14" />
                                        <label class="form-check-label" for="p14">Параметр 1</label>
                                    </div>
                                </div>
                                <div class="col-auto">
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="" id="p125" />
                                        <label class="form-check-label" for="p125">Параметр 2</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> -->
                        <div class="row gx-3 mt-4 pt-2">
                            <div class="col-auto">
                                <div class="addMenuItemInput addMenuItemInput--bold maw169">
                                    <label for="">День недели</label>
                                    <!-- //<input type="text" value="Пн, 06.12" class="brown"> -->
                                    <VueDatePicker v-model="date" no-header no-calendar-icon format="dd, DD.MM" class="brown" :min-date="new Date()" :allowed-dates="allowedDates" :disabled='this.$store.state.admin.dayOfWeek != 0'  placeholder="Дата"/>
                                </div>
                            </div>
                            <div class="col-auto">
                                <div class="addMenuItemInput addMenuItemInput--bold maw169">
                                    <label for="count">Количество</label>
                                     <the-mask id="count" mask="###" v-model.trim="count"/>
                                    <!-- <div class="invalid-feedback d-block ps-0" v-if="$v.count.$dirty && !$v.count.required">Введите количество</div> -->
                                </div>
                            </div>
                            <div class="col-auto">
                                <div class="addMenuItemInput addMenuItemInput--bold maw169">
                                    <label for="cell">Ячейка</label>
                                    <select class="form-select" aria-label="Default select example" v-model="selectedCell">
                                        <option :value="item.id" v-for="item in $store.state.admin.cells" :key="item.id" :selected='selectCell(item.id)'>{{item.name}}</option>
                                    </select>
                                    <!-- <input id="cell" type="number" v-model.trim="$v.cell.$model">
                                    <div class="invalid-feedback d-block ps-0" v-if="$v.cell.$dirty && !$v.cell.required">Введите количество</div> -->
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="modal-footer mt-5 mb-5 pt-3 pb-3">
                        <button class="btn-oval" type="submit">Сохранить изменения</button>
                        <button class="btn-oval" type="button" @click="updateDayParam()" :disabled='disableDayBtn'>Обновить параметры на день</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    TheMask
} from "vue-the-mask";
import {
    mapState
} from 'vuex'
import {
    required,
} from 'vuelidate/lib/validators'
import _ from 'lodash'
// import {
//     TheMask
// } from "vue-the-mask";
export default {
    data() {
        return {
            hexTokens: {
                F: {
                    pattern: /[7-8]/,
                    transform: (v) => v.toLocaleUpperCase(),
                },
                E: {
                    pattern: /[0-9]/,
                    transform: (v) => v.toLocaleUpperCase(),
                },
            },
            dobTokens: {
                F: {
                    pattern: /[0-9]/,
                    transform: (v) => v.toLocaleUpperCase(),
                },

            },

            name: "",
            desc: "",
            price: "",
            weight: "",
            calories: "",
            proteins: "",
            fat: "",
            carbohydrates: "",
            date: "",
            count: 0,
            cell: null,
            cellId: null,
            dietaryOptions: [],
            dietaryOptionsId: [],
            balance: null,
            is_breakfast: false,
            is_dinner: false,
            is_lunch: false,
            selectedCell: null,
            dateOfDay: null,
            week: [],
            file: '',
            thumb: null
        };
    },
    components: {
TheMask,
    },
    validations: {
        name: {
            required,
        },
        desc: {
            required,
        },
        price: {
            required,
        },
        weight: {
            required,
        },
        calories: {
            required,
        },
        proteins: {
            required,
        },
        fat: {
            required,
        },
        carbohydrates: {
            required,
        },
        // count: {
        //     required,
        // },

        // cell: {
        //     required,
        // },

    },
    computed: {
        ...mapState([
            'goods'
        ]),
         disableDayBtn: function () {
      // `this` указывает на экземпляр vm
     if(this.date == null || this.date == ''|| this.selectedCell==null){
     return true;
    } else{
        return false;
    }
    }
  
    },
    async mounted() {
        await this.$store.dispatch("actionGetCells")
        //Формируем список рабочих дней
        let week = []
        await this.$store.dispatch("actionGetDayOfWeek");
        for (let index = 0; index < 7; index++) {
            var res = this.$store.state.admin.days.find(item => item.date == this.$moment(new Date(new Date().setDate(new Date().getDate() + index))).format("YYYY-MM-DD"))
            if (res == undefined) {
                week.push(this.$moment(new Date(new Date().setDate(new Date().getDate() + index))).format("YYYY-MM-DD"))
            }
        }
        this.week = week
        this.date = week[0]
    },
    watch: {
        //Обнуляем поля при переход по датам в меню выбора датам
        '$store.state.admin.dayOfWeek': {
            //immediate: true,
            async handler() {
                if (this.$store.state.admin.dayOfWeek == 0) {
                   
                    this.count = 0
                    this.cell = null
                    this.is_breakfast = false
                    this.is_dinner = false
                    this.is_lunch = false
                    this.selectedCell = null

                     this.date = null;
                 this.cell=null;
                 this.cellId= null;
                 this.selectedCell= null;
                }
            }
        },
        '$store.state.admin.productParam.product.id': {
            //immediate: true,
            async handler() {
                if(this.$store.state.admin.dayOfWeek==0){
                 this.date = null;
                 this.count = null;
                 this.cell=null;
                 this.cellId= null;
                 this.selectedCell= null;
                }
                if (this.$store.state.admin.productParam.product.files != 0) {
                    this.thumb = this.$store.state.admin.productParam.product.files[0].thumb_path;
                }
                //  this.thumb = this.$store.state.admin.productParam.product.files[0].thumb_path;
                this.name = this.$store.state.admin.productParam.product.name
                this.desc = this.$store.state.admin.productParam.product.description
                this.price = this.$store.state.admin.productParam.product.price
                this.calories = this.$store.state.admin.productParam.product.calories
                this.fat = this.$store.state.admin.productParam.product.fats
                this.weight = this.$store.state.admin.productParam.product.weight
                this.proteins = this.$store.state.admin.productParam.product.proteins
                this.carbohydrates = this.$store.state.admin.productParam.product.carbohydrates
                this.dietaryOptions = this.$store.state.admin.productParam.product.dietary_options;
                this.dietaryOptionsId.length = 0
                this.dietaryOptions.forEach(element => {
                    if (element.is_active) {
                        this.dietaryOptionsId.push(element.id)
                    }
                })

            }

        },
        '$store.state.admin.productParamDay.product_id': {
            //immediate: true,
            async handler() {

                this.cell = this.$store.state.admin.productParamDay.cell
                this.cellId = this.$store.state.admin.productParamDay.cell_id
                this.count = this.$store.state.admin.productParamDay.count
                this.balance = this.$store.state.admin.productParamDay.balance
                this.is_breakfast = this.$store.state.admin.productParamDay.is_breakfast
                this.is_dinner = this.$store.state.admin.productParamDay.is_dinner
                this.is_lunch = this.$store.state.admin.productParamDay.is_lunch
                this.selectedCell = this.cellId
                this.dateOfDay = this.$store.state.admin.productParamDay.date
                this.date = this.dateOfDay
            }

        },
       async date(){
           // alert(this.$store.state.admin.productParam.product.id)
              await this.$store.dispatch("actionGetParamOnDay", {
                    id: this.$store.state.admin.productParam.product.id,
                    date: this.date
                });
        }
    },
    methods: {
        changeSelect(id) {
            //this.dietaryId.push(id); 
            var index = this.dietaryOptionsId.indexOf(id);
            if (index == -1) {
                this.dietaryOptionsId.push(id);
            } else {
                this.dietaryOptionsId.splice(index, 1);
            }

            console.log(this.dietaryOptionsId);
        },
        submitHandler: _.throttle(async function () {
            if (this.$v.$invalid) {
                this.$v.$touch();
                return;
            }
            const data = {
                id: this.$store.state.admin.productParam.product.id,
                proteins: this.proteins,
                fats: this.fat,
                carbohydrates: this.carbohydrates,
                dietary_options: this.dietaryOptionsId
            }
            await this.$store.dispatch("actionUpdateParamsProducts", data)
            this.$toast.success("Изменения сохранены");

        }, 5000),
        // async submitHandler() {
        //     if (this.$v.$invalid) {
        //         this.$v.$touch();
        //         return;
        //     }
        //     const data = {
        //         id: this.$store.state.admin.productParam.product.id,
        //         proteins: this.proteins,
        //         fats: this.fat,
        //         carbohydrates: this.carbohydrates,
        //         dietary_options: this.dietaryOptionsId
        //     }
        //     await this.$store.dispatch("actionUpdateParamsProducts", data)

        //     console.log(data)

        // },
        updateDayParam: _.throttle(async function () {
            const data = {
                id: this.$store.state.admin.productParam.product.id,
                date: this.date,
                is_breakfast: this.is_breakfast,
                is_lunch: this.is_lunch,
                is_dinner: this.is_dinner,
                cell_id: this.selectedCell,
                count: this.count
            }
           const res =  await this.$store.dispatch("actionUpdateParamOnDay", data)
          if(res.status){
  this.$toast.success("Изменения сохранены");
          } else{
            this.$toast.info(res.message);
          }
          
        }, 5000),

        // async updateDayParam() {
        //             const data = {
        //                 id: this.$store.state.admin.productParam.product.id,
        //                 date: this.date,
        //                 is_breakfast: this.is_breakfast,
        //                 is_lunch: this.is_lunch,
        //                 is_dinner: this.is_dinner,
        //                 cell_id: this.selectedCell,
        //                 count: this.count
        //             }
        //             await this.$store.dispatch("actionUpdateParamsProducts", data)
        //         },
        selectCell(id) {
            if (this.cellId == id) {
                this.selectedCell == id
                return true
            }
        },
        allowedDates: function (date) {
            console.log('allowedDates', this.week.some(item => JSON.stringify(item) == JSON.stringify(date)))
            return this.week.some(item => JSON.stringify(item) == JSON.stringify(date));
        },
        // handleFileUpload() {
        //     this.file = this.$refs.file.files[0];
        //     console.log(this.file)
        // },
        // async submitFile() {
        //     let formData = new FormData();
        //     formData.append('file', this.file);
        //     await this.$store.dispatch("actionUpdatePhoto", {
        //         id: this.$store.state.admin.productParam.product.id,
        //         image: formData
        //     })
        // }
    },
};
</script>

<style lang="scss" scoped>
.form-check-label {
    font-family: Montserrat;
    margin-left: 12px;
    font-size: 16px;
    line-height: 20px;
    cursor: pointer;
    color: #000000;
    margin-top: 5px;
}

.form-check-input {
    background: #e7e7e7;
    width: 23px;
    height: 23px;

    &:checked {
        background-color: $orange;
        border-color: $orange;
    }

    &:checked[type="checkbox"] {
        background-image: none;
    }
}

.btn-close {}

.addMenuItem {

    &__img {
        img {
            max-width: 100%;
            height: auto;
        }
    }

    &__img-add {
        text-align: center;

        input {
            display: none;
        }

        label {
            font-family: Montserrat;
            border-bottom: 1px solid #000;
            font-size: 14px;
            cursor: pointer;
            line-height: 1;
        }
    }

    &__header {
        font-size: 24px;
        line-height: 142.69%;
        margin-bottom: 18px;
    }

    &__subheader {
        font-family: Montserrat;
        font-size: 16px;
    }
}

.addMenuItemInput {
    label {
        display: block;
        font-family: Montserrat;
        font-size: 16px;
        padding-left: 25px;
        margin-bottom: 5px;
    }

    input {
        background: #EBEBEB;
        border-radius: 73px;
        border: none;
        padding: 15px 10px 15px 25px;
        width: 100%;
        font-family: Montserrat;
        font-size: 16px;
        font-weight: 600;

        &:focus {
            border: none;
            outline: none;
        }
    }

    &--bold {
        label {
            font-weight: 700;
            padding-left: 0;
        }
    }
}

.form-check {}

.form-check-input {}

.form-check-label {}

.modal-footer {}

.modal-body {
    padding: 33px 31px 0 32px;
}

@media (min-width: 576px) {
    .modal-dialog {
        max-width: 970px;
        margin: 1.75rem auto;
    }

}

.maw169 {
    max-width: 169px;
}

.brown {
    background: #3F2A2C !important;
    color: #fff !important;
}

.modal-footer {
    border: none;
    display: flex;
    justify-content: center;
}

.maw403 {
    max-width: 403px;
}

.btn-close {
    position: absolute;
    background: none;
    right: -64px;

    &:focus {
        box-shadow: none;
        outline: none;
    }
}

.available-time {
    input[type="checkbox"] {
        opacity: 0;
        position: fixed;
        width: 0;
    }

    label {
        display: inline-block;
        background-color: #fff;
        padding: 10px 0;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 109.5%;
        /* or 15px */

        color: #626262;

        border: 2px solid #f5f5f5;
        margin-bottom: 10px;
        border-radius: 12px;
        margin-right: 10px;
        text-align: center;
        // width: 160px;
        padding-left: 33px;
        padding-right: 33px;
        cursor: pointer;
        background: #f5f5f5;
    }

    // label:hover {
    //     background-color: $orange;
    //     border-color: $orange;
    //     color: #fff;
    // }

    // input[type="checkbox"]:focus+label {
    //     background-color: $orange;
    //     border-color: $orange;
    //     color: #fff;
    // }

    input[type="checkbox"]:checked+label {
        background-color: $orange;
        border-color: $orange;
        color: #fff;
    }
}

.invalid-feedback {
    padding-left: 25px;
}

.brown {
    border-radius: 73px !important;
    background: #3F2A2C !important;
    overflow: hidden;

    input {
        padding-top: 150px !important;
        padding-bottom: 105px !important;
        height: 100px !important;
        border: none !important;
        height: 100px !important;

    }
}

.form-select {
    width: 120px;
    height: 50px;
    border-radius: 50px;
    background: #EBEBEB;
    border-color: #EBEBEB;
}

.available-time-header--low {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height */

    color: #000000;
}

.addMenuItem__img {
    img {
        border-radius: 12px;
    }
}

.food-item__noimg {
    padding-top: 82%;
    width: 100%;
    background: url(../assets/nophoto.png) 50% 50% no-repeat #EBEBEB;
    border-radius: 12px;
}
</style>
