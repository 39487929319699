<template>
<div>
        <div class="col-md-12 autoOrderItem">   
         <DayOfWeek/>
        </div>
        <div class="cabinet">
            <div class="cabinet__nav">
                <NavbarVerticalAdmin />
            </div>
            <div class="cabinet__content contacts">
                <div class="row">
                     <Loader v-if="loader"/>
                     <template v-for="good in $store.state.admin.groupItems.groupList.products">
                              <!-- <FoodItem :good=good :key="good.id" /> -->
                               <AdminMenuItem data-bs-toggle="modal" data-bs-target="#addMenu" :good=good :key="good.id"/>
                    </template>
                </div>
            </div>
        </div>
    <Modal/>
    <ModalColor/>
</div>
</template>

<script>
//import Navbar from '@/components/Navbar.vue'
import NavbarVerticalAdmin from '@/components/NavbarVerticalAdmin.vue'
import AdminMenuItem from '@/components/AdminMenuItem.vue'
//import AdminMenuHorizont from '@/components/AdminMenuHorizont.vue'
import Modal from '@/components/Modal.vue'
import ModalColor from '@/components/ModalColor.vue'
import DayOfWeek from '@/components/DayOfWeek.vue'
import { mapState } from 'vuex'
export default {
    name: "Cart",
    components: {
      //  Navbar,
        NavbarVerticalAdmin,
        AdminMenuItem,
       // AdminMenuHorizont,
        Modal,
        ModalColor,
        DayOfWeek
    },
        head: {
        title: {
            inner: 'Меню',
            separator: '-',
            complement: 'Администратор'
        },
    },
    async mounted() {
        const data ={
            id: 1,
          //  date: '2022-07-05'
        }
        this.$store.commit('loader', true);
        await this.$store.dispatch("actionGetGroupAdmin", data);
        this.$store.commit('loader', false);
    },
         computed: {
        ...mapState([
            'goods','loader'
        ])
    },
}
</script>

<style lang="scss" scoped>
.admin-header {
    font-size: 40px;
    line-height: 100.74%;
}

.cabinet {
    margin-top: 35px;
    padding-bottom: 80px;
    margin-top: 24px;
    display: flex;
    justify-content: space-between;
position: relative;
    &__nav {
        width: 250px;
    }

    &__content {
        width: calc(100% - 326px);
        //background: #FFFFFF !important;

    }

}
.contacts{
    position: relative;
}

</style>
