<template>
    <div class="colors__items">
        <label><input type="radio" :name="catId" class="c11" value="" :checked="color=='#000000'" @click="selectColor(catId, '#000000')"/> </label>
        <label><input type="radio" :name="catId" class="c12" value="" :checked="color=='#FF7728'" @click="selectColor(catId, '#FF7728')"/> </label>
        <label><input type="radio" :name="catId" class="c13" value="" :checked="color=='#0041E8'" @click="selectColor(catId, '#0041E8')"/> </label>
        <label><input type="radio" :name="catId" class="c14" value="" :checked="color=='#CD006F'" @click="selectColor(catId, '#CD006F')"/> </label>
        <label><input type="radio" :name="catId" class="c15" value="" :checked="color=='#008E1F'" @click="selectColor(catId, '#008E1F')"/> </label>
        <label><input type="radio" :name="catId" class="c16" value="" :checked="color=='#B9002C'" @click="selectColor(catId, '#B9002C')"/> </label>
        <label><input type="radio" :name="catId" class="c17" value="" :checked="color=='#00748D'" @click="selectColor(catId, '#00748D')"/> </label>
        <label><input type="radio" :name="catId" class="c18" value="" :checked="color=='#898C00'" @click="selectColor(catId, '#898C00')"/> </label>
        <label><input type="radio" :name="catId" class="c19" value="" :checked="color=='#9100EA'" @click="selectColor(catId, '#9100EA')"/> </label>
    </div>
</template>
<script>
export default {

    data() {
        return {
 
        };
    },
    props: {
       nameRadio:String,
       catId: Number,
       color:String
    },
    methods: {
      selectColor(id,color){
           this.$store.commit('SET_GROUP_COLOR', {'color':color,'id':id});

     },
 
    },
};
</script>
<style lang="scss">
    .colors__items {
    input[type=radio] {
        padding: 13px 17px;
        appearance: none;
        opacity: .2;
        border-radius: 5px;
        margin-right: 5px;
         cursor: pointer !important;

    }

    input[type=radio]:checked {
        display: inline-block;
        opacity: 1;
         cursor: pointer !important;
    }
    label{
            cursor: pointer !important;
        }
}
.c11 {
    background: #000;
}

.c12 {
    background: #FF7728;
}

.c13 {
    background: #0041E8;
}

.c14 {
    background: #CD006F;
}

.c15 {
    background: #008E1F;
}

.c16 {
    background: #B9002C;
}

.c17 {
    background: #00748D;
}

.c18 {
    background: #898C00;
}

.c19 {
    background: #9100EA;
}

</style>