<template>
<div class="col-md-4 mb-4" @click='getParamProduct(good.id)'>
    <div class="food-item">
        <div class="food-item__img"  v-if="good.files.length!=0">
           <img :src="`https://elimleli2.itfactory.site${good.files[0].thumb_path}`" />
            </div>
              <div class="food-item__noimg" v-else></div>
        <div class="food-item__meta pb-5">
            <div class="food-item__name">{{good.name}}</div>
            <div class="food-item__weight"><span>{{good.propertyValues['137'].values[0]}} гр</span><i></i><span>{{good.propertyValues['138'].values[0]}} ккал</span><i></i>БЖУ: {{good.propertyValues['141'].values[0]}}/{{good.propertyValues['142'].values[0]}}/{{good.propertyValues['143'].values[0]}}</div>
            <div class="food-item__desc">{{good.desc}}</div>
        </div>
        <div class="food-item__footer">
            <div class="row justify-content-center">
                <div class="col-4 d-flex align-items-center">
                    <div class="food-item__color" :style="{backgroundColor: good.group.color}"></div>
                </div>
                <div class="col-8">
                    <div class="food-item__price">{{good.price}}<span> руб.</span></div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    props: {
        good: {
            type: Object,
        }
    },
    methods: {
        async getParamProduct(id) {
            await this.$store.dispatch("actionGetParamsProducts", id);
            //если дата все то не отпарвляем запрос на получения данных продукта на день
            // this.$store.commit('SET_PRODUCT_PARAM_DAY', []);
            if (this.$store.state.admin.dayOfWeek != 0) {
                await this.$store.dispatch("actionGetParamOnDay", {
                    id: id,
                    date: this.$store.state.admin.dayOfWeek
                });
            }

        }

    },
}
</script>

<style lang="scss" scoped>
.food-item {
    background: #F5F5F5;
    border-radius: 12px;
    overflow: hidden;
    cursor: pointer;
    height: 100%;
    margin-bottom: 5px;
    max-height: 460px;
    position: relative;
}

.food-item__img {
     max-height: 255px;
     overflow: hidden;
    img {
        width: 100%;
        height: auto;
    }
}

.food-item__meta {
    padding: 15px 20px;
}

.food-item__name {
    font-weight: bold;
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 9px;
}

.food-item__weight {
    font-size: 14px;
    line-height: 17px;
    font-family: Montserrat;
    margin-bottom: 10px;

    align-items: center;

    color: #828282;
    i{
        position: relative;
        bottom: 2px;
        width: 4px;
        height: 4px;
        display: inline-block;
        background: #828282;
        border-radius: 50%;
        margin-left: 5px;
        margin-right: 5px;

    }
}

.food-item__desc {
    font-family: Montserrat;
    margin-bottom: 20px;
    font-size: 14px;
    line-height: 17px;
}

.food-item__price {
    color: $orange;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    white-space: nowrap;
    text-align: right;

    span {

        // display: block;
        font-size: 24px;

    }
}

.food-item__add-cart {}

.btn-orange-middle {
    @extend .btn;
    background: $orange;
    white-space: nowrap;
    border-radius: 12px;
    font-weight: 600;
    font-family: Montserrat;
    font-size: 16px;
    line-height: 20px;
    color: #fff;
    padding: 14px 22px;
}

.food-item__color {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: #000;
}
.food-item__footer{
    position: absolute;
    left: 15px;
   padding-right: 30px;
    width: 100%;
    bottom: 20px;
}
.food-item__noimg{
    padding-top: 82%;
    width: 100%;
      background: url(../assets/nophoto.png) 50% 50% no-repeat #EBEBEB;
      border-radius: 12px 12px 0 0;
}
</style>
