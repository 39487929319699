<template>
<div>
    <!-- Modal -->
    <div class="modal fade" id="menuColor" tabindex="-1" aria-labelledby="menuColor" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <h1 class="colors-header">Редактирование цвета категорий</h1>
                <button type="button" ref="myBtn" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                    <img :src="require('../assets/closeModal.svg')" />
                </button>
                <div class="modal-body addMenuItem">
                    <div class="row gx-0">
                        <div class="col-md-6" v-for="cat in $store.state.operator.groupList" :key="cat.id">
                            <div class="colors">
                                <div class="colors__header">{{cat.name}}</div>
                                <RadioColors nameRadio="c1" :catId='cat.id' :color='cat.color'/>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="modal-footer pb-2 mb-3">
                    <button class="btn-oval" @click="sendColor()">Сохранить</button>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import RadioColors from '@/components/RadioColors.vue';
export default {
    components: {
        RadioColors
    },
    methods: {
        async sendColor() {
            const data = {
                color: this.$store.state.operator.color.color,
                group_id: this.$store.state.operator.color.group_id
            }
            await this.$store.dispatch("actionSetColorGroup", data);
            const elem = this.$refs.myBtn
            elem.click()
        }
    },
}
</script>

<style lang="scss" scoped>
.modal-body {
    padding: 18px 10px 0 82px;
}

@media (min-width: 576px) {
    .modal-dialog {
        max-width: 970px;
        margin: 1.75rem auto;
    }

}

.colors-header {
    font-size: 24px;
    text-align: center;
    margin-top: 45px;
    padding-bottom: 10px;
}

.modal-footer {
    border: none;
    display: flex;
    justify-content: center;
}

.maw403 {
    max-width: 403px;
}

.btn-close {
    position: absolute;
    background: none;
    right: -64px;

    &:focus {
        box-shadow: none;
        outline: none;
    }
}

.colors {
    margin-bottom: 36px;

    &__header {
        font-family: Montserrat;
        font-weight: 500;
        font-size: 18px;
        margin-bottom: 0;
    }

    &__items {
        margin-top: 9px;
        
    }
} 

</style>
