<template>
<div class="navbar-vertical">
    <button to="#"  class="navbar-vertical__btn active"  :style="{ 'background': cat.color}" :class="{ activeBtn: isActive == cat.id}" v-for="cat in $store.state.operator.groupList" :key="cat.id" v-on:click="changeCat(cat.id)">
        {{cat.name}}
    </button>
    <router-link to="/" class="editColor" data-bs-toggle="modal" data-bs-target="#menuColor" >Редактировать цвета</router-link>
     <span class="editColor" v-if="$store.state.admin.dayOfWeek!=0" @click="generateCell()">Заполнить ячейки блюд</span>
    <!-- <router-link to="/contacts" class="navbar-vertical__btn active" style="background:#000;">Десерты и выпечка</router-link>
    <router-link to="/order-history" class="navbar-vertical__btn" style="background:#FF7728;">Салаты</router-link>
    <router-link to="/inner-bill" class="navbar-vertical__btn" style="background:#0041E8;">Первые блюда</router-link>
    <router-link to="/filter-settings" class="navbar-vertical__btn" style="background:#CD006F;">Вторые блюда</router-link>
    <router-link to="/auto-order" class="navbar-vertical__btn mb-3" style="background:#008E1F;">Гарниры</router-link>
    <router-link to="/" class="editColor" data-bs-toggle="modal" data-bs-target="#menuColor">Редактировать цвета</router-link> -->
</div>
</template>

<script>
import {
    mapState
} from 'vuex'
export default {

    components: {

    },
    data() {
        return {
        isActive: 1
        };
    },

    computed: {
        ...mapState([
            'category'
        ])
    },
    async mounted() {
          await this.$store.dispatch("actionGetAllGroups");
    },
    methods: {
        async changeCat(id) {
            this.isActive = id
            this.$store.commit('loader', true);
             this.$store.commit('SET_CAT_GROUP', id);
            await this.$store.dispatch("actionGetGroupAdmin", {id:id, date: this.$store.state.admin.dayOfWeek});
            this.$store.commit('loader', false);
        },
        async generateCell(){
              await this.$store.dispatch("actionGenerateCells",{date: this.$store.state.admin.dayOfWeek});
           this.$toast.success("Ячейки блюд заполнены");

        }
    }
};
</script>

<style lang="scss" scoped>
.navbar-vertical {
    width: 244px;
    text-align: center;

    &__btn {
        @extend .btn;
        width: 100%;
        padding-top: 16px;
        padding-bottom: 16px;
        margin-bottom: 8px;
        background: #EBEBEB;
        border-radius: 12px;
        font-family: Montserrat;

        font-weight: 600;
        font-size: 16px;
        line-height: 109.5%;
        /* identical to box height, or 18px */
        opacity: .5;
        color: #fff;
        &:hover{
            color:#fff;
        }

    }
}

.router-link-exact-active {
    background: $orange;
    color: #fff;
}

.editColor {
     display: inline-block;
    font-family: Montserrat;
    color: #000000;
    text-decoration: none;
    text-align: center;
    cursor: pointer;
margin-top: 10px;
    border-bottom: 1px solid #000;
    &:hover{
        color: #000000;
    }
}

.active {
    opacity: 1;
}
.activeBtn{
    opacity: 0.5;
}
</style>
